import axios from 'axios';


export default function adminRequest(config) {
   // console.log(config);
    var defer = new Promise(function (resolve, reject) {
       // console.log('请求数据：', config.data);
        //console.log("process.env.NODE_ENV:" + process.env.NODE_ENV);
        //发送配置
        var requestConfig = {
            method: config.method ? config.method : "post",
            url: config.url,
            data: config.data ? config.data : null,
            //baseURL: process.env.NODE_ENV == "development" ? "https://localhost:44308/" : "http://www.quxueedu.cn:668/api",
            baseURL:"http://www.quxueedu.cn:668/api",
            
            withCredentials: false,
            timeout: 300000,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            }
        }

        //send Ajax
        axios(requestConfig).then(function (res) {
           // console.log('响应数据：', res.data);
            resolve(res.data);
        }, function (err) {
            console.log(err);
            reject(err);
        }).catch(function (err) {
            console.log(err);
            reject(err);
        });
    })
    return defer;
}
